import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { usePrefersReducedMotion } from '../../../hooks';
import { navDelay, loaderDelay } from '../../../utils';
import { StyledHeroSection } from './styles';
import React, { useState, useEffect } from 'react';
import { email } from '../../../config';

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hello, my name is</h1>;
  const two = <h2 className="big-heading">Settani Abderrahman</h2>;
  const three = <h3 className="big-heading">I'm a full stack developer.</h3>;
  const four = (
    <p>
      I'm absolutely thrilled about computer science and development! As a passionate software
      engineer, I specialize in building and designing exceptional websites, applications, and
      mobile applications - I love everything in between too! My interests also extend to AI and VR,
      which keep me engaged and driven in my work. Currently, I'm working on some amazing projects,
      where I focus on building high-quality software solutions for clients.
      Additionally, I offer consulting services, and my clients love my approach and the results that I deliver!
    </p>
  );
  const five = (
    <a href={`mailto:${email}`} className="email-link">
      Get In Touch
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
